/* Grid.css */
.grid-container {
  display: grid;
  grid-template-columns: repeat(100, 1fr); /* 创建 100 列 */
  grid-template-rows: repeat(100, 1fr); /* 创建 100 行 */
  gap: 2px; /* 设置格子之间的间隙 */
}

.grid-cell {
  width: 80px; /* 每个格子的宽度 */
  height: 80px; /* 每个格子的高度 */
}

.gradient-title {
  background: linear-gradient(90deg, #f6f6f6, #bababa 25%, #717171 30%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 200%;
  animation: gradientAnimation 10s ease infinite;
}

.gradient-1 {
  background-image: url(./asset/bgMask.svg),
    linear-gradient(135deg, #ffde90 0%, #ce6a22 100%);
  background-size: cover;
  background-position: center;
}

.gradient-2 {
  background-image: url(./asset/bgMask.svg),
    linear-gradient(135deg, #d7b3fc 0%, #782586 100%);
  background-size: cover;
  background-position: center;
}

.gradient-3 {
  background-image: url(./asset/bgMask.svg),
    linear-gradient(135deg, #fab7c7 0%, #d76e8a 100%);
  background-size: cover;
  background-position: center;
}

.gradient-4 {
  background-image: url(./asset/bgMask.svg),
    linear-gradient(135deg, #cce5c6 0%, #84ac72 100%);
  background-size: cover;
  background-position: center;
}

.gradient-5 {
  background-image: url(./asset/bgMask.svg),
    linear-gradient(135deg, #d4eaf6 0%, #398cc3 100%);
  background-size: cover;
  background-position: center;
}

.carousel-outer-container {
  width: 100%; /* 视图的宽度 */
  overflow-x: hidden; /* 隐藏水平滚动条 */
  position: relative;
}

.carousel-container {
  display: flex;
  gap: 20px;
  white-space: nowrap; /* 防止内容折行 */
  animation: scrollRightToLeft 10s linear infinite;
}

.carousel-item {
  width: 60px; /* 单个项目的宽度 */
  height: 60px;
  flex: 0 0 auto; /* 防止 flexbox 压缩项目 */
}

@keyframes scrollRightToLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%); /* 移动至总宽度的一半 */
  }
}

.custom-shadow {
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 1))
    drop-shadow(0px 0px 30px rgba(29, 29, 29, 1))
    drop-shadow(0px 0px 50px rgba(29, 29, 29, 1));
}

.rotated-icon {
  transform: rotate(45deg); /* Or 90 degrees based on your preference */
  transition: transform 0.3s ease-in-out;
}
